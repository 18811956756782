// Copyright 2014 The Oppia Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS-IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
/**
 * @fileoverview Service to dynamically construct translation ids for i18n.
 */
var oppia = require('AppInit.ts').module;
oppia.factory('ConstructTranslationIdsService', [
    function () {
        return {
            // Construct a translation id for library from name and a prefix.
            // Ex: 'categories', 'art' -> 'I18N_LIBRARY_CATEGORIES_ART'
            getLibraryId: function (prefix, name) {
                return ('I18N_LIBRARY_' + prefix.toUpperCase() + '_' +
                    name.toUpperCase().split(' ').join('_'));
            }
        };
    }
]);
